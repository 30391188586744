import { SAFETY_AUTH_URL } from '@config'

// 获取 `人员` 分页数据
export const GET_USER_LIST = {
    url: `${SAFETY_AUTH_URL}/user-manager/page`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 新增 `人员` 信息
export const APPEND_USER = {
    url: `${SAFETY_AUTH_URL}/user-manager/save`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 修改 `人员` 信息
export const UPDATE_USER = {
    url: `${SAFETY_AUTH_URL}/user-manager/update`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 获取 `人员` 下拉选项数据
export const GET_USER_OPTIONS = {
    url: `${SAFETY_AUTH_URL}/user-manager/list`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}
