<!-- 检查人/人员列表 下拉 -->
<template>
    <a-select
        v-model="modelState"
        :loading="loading"
        :disabled="disabled"
        :allow-clear="allowClear"
        :placeholder="placeholder"
        @blur="e => $emit('blur', e)"
    >
        <a-select-option v-for="item in userOptions" :key="item.userCode">
            {{ item.realName }}
        </a-select-option>
    </a-select>
</template>
<script>
import { Select } from 'ant-design-vue'

import getUserOptionsService from '@service/systemManage/user/getUserOptionsService'

export default {
    name: 'CheckPersonSelect',
    components: {
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        value: {
            type: [Number, String]
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowClear: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: false,
            userOptions: []
        }
    },
    computed: {
        modelState: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
                this.$emit('change', v)
            }
        }
    },
    created() {
        this.getUserOptions()
    },
    methods: {
        async getUserOptions() {
            try {
                this.loading = true
                this.userOptions = await getUserOptionsService()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
