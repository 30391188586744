import axios from '@service'
import { GET_USER_OPTIONS } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 获取 `人员` 下拉选项数据
export default async function getUserOptionsService() {
    const { data } = await axios(GET_USER_OPTIONS)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || []
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
